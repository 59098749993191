// @flow
import { Cookies } from "react-cookie";
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { myConfig } from '../config';

import {
    LOGIN_USER,
    LOGOUT_USER,
    REGISTER_USER,
    FORGET_PASSWORD,
    PARTNER_VERIFY,
    PARTNER_TEMPLATE_LIST,
    PARTNER_PORTFOLIO_LIST,
    NEW_PASSWORD,
    USER_INFO,
    USER_INFO_SAVE,
    GOOGLE_USER,
    FACEBOOK_USER
} from '../../constants/actionTypes';


import {
    loginUserSuccess,
    loginUserFailed,
    //registerUserSuccess,
    registerUserFailed,
    forgetPasswordSuccess,
    forgetPasswordFailed,
    partnerVerifySuccess,
    partnerVerifyFailed,
    partnerTemplateListSuccess,
    partnerTemplateListFailed,
    partnerPortfolioListSuccess,
    partnerPortfolioListFailed,
    newPasswordSuccess,
    newPasswordFailed,
    userInfoSuccess,
    userInfoFailed,
    //googleUserSuccess,
    googleUserFailed,
    facebookUserFailed
} from './actions';

//import { siteClear } from '../site/actions';


/**
 * Fetch data from given url
 * @param {*} url 
 * @param {*} options 
 */
const fetchJSON = (url, options = {}) => {
    return fetch(url, options)
        .then(response => {
            if (!response.status === 200) {
                throw response.json();
            }
            return response.json();
        })
        .then(json => {
            return json;
        })
        .catch(error => { throw error });
}


/**
 * Sets the session
 * @param {*} user 
 */
const setSession = (user) => {
    let cookies = new Cookies();
    if (user){
        cookies.set("user", JSON.stringify(user), { path: "/" });
    }
    else{
        cookies.remove("user");
    }
};
/**
 * Login the user
 * @param {*} payload - username and password 
 */
function* login({ payload: { username, password, partner_id, partner_brand, partner_system_name, partner_domain, partner_backend_url, partner_help_url, partner_favicon, partner_theme, partner_allow_crete_project, partner_logo } }) {
    const options = {
        body: JSON.stringify({ username, password, partner_id }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey }
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/users/authenticate`, options);
        if(response['status']){
            let responseData = response['data'];
            let responseJson = {
                id: responseData['ID'],
                username: responseData['user_email'],
                fullname: responseData['display_name'],
                user_type: responseData['user_type'],
                role: "Admin",
                token: response['token'],
                partner_id: partner_id,
                partner_brand: partner_brand,
                partner_system_name: partner_system_name,
                partner_domain: partner_domain,
                partner_backend_url: partner_backend_url,
                partner_help_url: partner_help_url,
                partner_favicon: partner_favicon,
                partner_theme: partner_theme,
                partner_allow_crete_project: partner_allow_crete_project,
                partner_logo: partner_logo
            };

            setSession(JSON.stringify(responseJson));
            yield put(loginUserSuccess(JSON.stringify(responseJson)));
        }
        else{
            yield put(loginUserFailed(response['message']));
            setSession(null);
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(loginUserFailed(message));
        setSession(null);
    }
}


/**
 * Logout the user
 * @param {*} param0 
 */
function* logout({ payload: { history } }) {
    try {
        setSession(null);
        //yield put(siteClear());
        
        yield call(() => {
            history.push("/login");
            window.location = "/login";
        });
    } catch (error) { }
}

/**
 * Register the user
 */
function* register({ payload: { fullname, email, password, tel, partner_id, partner_brand, partner_system_name, partner_domain, partner_backend_url, partner_help_url, partner_favicon, partner_theme, partner_allow_crete_project, partner_logo } }) {
    const options = {
        body: JSON.stringify({ fullname, email, password, tel, partner_id }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey  }
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/users/register`, options);
        if(response['status']){
            let responseData = response['data'];
            let responseJson = {
                id: responseData['ID'],
                username: responseData['user_email'],
                fullname: responseData['display_name'],
                user_type: responseData['user_type'],
                role: "Admin",
                token: response['token'],
                partner_id: partner_id,
                partner_brand: partner_brand,
                partner_system_name: partner_system_name,
                partner_domain: partner_domain,
                partner_backend_url: partner_backend_url,
                partner_help_url: partner_help_url,
                partner_favicon: partner_favicon,
                partner_theme: partner_theme,
                partner_allow_crete_project: partner_allow_crete_project,
                partner_logo: partner_logo
            };

            setSession(JSON.stringify(responseJson));
            yield put(loginUserSuccess(JSON.stringify(responseJson)));

            //yield put(registerUserSuccess(response));
        }
        else{
            yield put(registerUserFailed(response['message']));
            setSession(null);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(registerUserFailed(message));
        setSession(null);
    }
} 

function* googleauth({ payload: { fullname, email, googleId, profile_image, partner_id, partner_brand, partner_system_name, partner_domain, partner_backend_url, partner_help_url, partner_favicon, partner_theme, partner_allow_crete_project, partner_logo } }) {
    const options = {
        body: JSON.stringify({ fullname, email, googleId, profile_image, partner_id }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey  }
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/users/gauth`, options);
        if(response['status']){
            let responseData = response['data'];
            let responseJson = {
                id: responseData['ID'],
                username: responseData['user_email'],
                fullname: responseData['display_name'],
                user_type: responseData['user_type'],
                user_profile_image: responseData['user_profile_image'],
                role: "Admin",
                token: response['token'],
                partner_id: partner_id,
                partner_brand: partner_brand,
                partner_system_name: partner_system_name,
                partner_domain: partner_domain,
                partner_backend_url: partner_backend_url,
                partner_help_url: partner_help_url,
                partner_favicon: partner_favicon,
                partner_theme: partner_theme,
                partner_allow_crete_project: partner_allow_crete_project,
                partner_logo: partner_logo
            };

            setSession(JSON.stringify(responseJson));
            yield put(loginUserSuccess(JSON.stringify(responseJson)));

            //yield put(registerUserSuccess(response));
        }
        else{
            yield put(googleUserFailed(response['message']));
            setSession(null);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(googleUserFailed(message));
        setSession(null);
    }
} 

function* facebookauth({ payload: { fullname, email, facebookId, profile_image, partner_id, partner_brand, partner_system_name, partner_domain, partner_backend_url, partner_help_url, partner_favicon, partner_theme, partner_allow_crete_project, partner_logo } }) {
    const options = {
        body: JSON.stringify({ fullname, email, facebookId, profile_image, partner_id }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey  }
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/users/fauth`, options);
        if(response['status']){
            let responseData = response['data'];
            let responseJson = {
                id: responseData['ID'],
                username: responseData['user_email'],
                fullname: responseData['display_name'],
                user_type: responseData['user_type'],
                user_profile_image: responseData['user_profile_image'],
                role: "Admin",
                token: response['token'],
                partner_id: partner_id,
                partner_brand: partner_brand,
                partner_system_name: partner_system_name,
                partner_domain: partner_domain,
                partner_backend_url: partner_backend_url,
                partner_help_url: partner_help_url,
                partner_favicon: partner_favicon,
                partner_theme: partner_theme,
                partner_allow_crete_project: partner_allow_crete_project,
                partner_logo: partner_logo
            };

            setSession(JSON.stringify(responseJson));
            yield put(loginUserSuccess(JSON.stringify(responseJson)));

            //yield put(registerUserSuccess(response));
        }
        else{
            yield put(facebookUserFailed(response['message']));
            setSession(null);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(facebookUserFailed(message));
        setSession(null);
    }
}

/**
 * forget password
 */
function* forgetPassword({ payload: { username } }) {
    const options = {
        body: JSON.stringify({ username }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const response = yield call(fetchJSON, '/users/password-reset', options);
        yield put(forgetPasswordSuccess(response.message));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(forgetPasswordFailed(message));
    }
}

function* newpassword({ payload: { userid, password, newpassword, partner_id, token } }) {
    const options = {
        body: JSON.stringify({ userid, password, newpassword, partner_id }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/users/changepassword`, options);
        if(response['status']){
            yield put(newPasswordSuccess(response['status']));
        }
        else{
            yield put(newPasswordFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(newPasswordFailed(message));
    }
    
}

function* partnerVerify({ payload: { hostname } }) {
    const options = {
        body: JSON.stringify(),
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': myConfig.partnerToken}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/users/${hostname}`, options);
        if(response['status']){
            yield put(partnerVerifySuccess(response['data']));
        }
        else{
            yield put(partnerVerifyFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(partnerVerifyFailed(message));
    }
}

function* partnertemplatelist({ payload: { partnerid } }) {
    const options = {
        body: JSON.stringify(),
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': myConfig.partnerToken}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/site/partnertemplates/${partnerid}`, options);
        if(response['status']){
            yield put(partnerTemplateListSuccess(response['data']));
        }
        else{
            yield put(partnerTemplateListFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(partnerTemplateListFailed(message));
    }
}

function* partnerportfoliolist({ payload: { partnerid } }) {
    const options = {
        body: JSON.stringify(),
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': myConfig.partnerToken}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/site/portfolio/${partnerid}`, options);
        if(response['status']){
            yield put(partnerPortfolioListSuccess(response['data']));
        }
        else{
            yield put(partnerPortfolioListFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(partnerPortfolioListFailed(message));
    }
}

function* userinfo({ payload: { token } }) {
    const options = {
        body: JSON.stringify(),
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/users/userinfo`, options);
        if(response['status']){
            yield put(userInfoSuccess(response['data']));
        }
        else{
            yield put(userInfoFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(userInfoFailed(message));
    }
}

function* userinfosave({ payload: { data, token } }) {
    const options = {
        body: JSON.stringify({data}),
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/users/userinfo`, options);
        if(response['status']){
            yield put(userInfoSuccess(response['data']));
        }
        else{
            yield put(userInfoFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(userInfoFailed(message));
    }
}

export function* watchPartnerTemplateList():any {
    yield takeEvery(PARTNER_TEMPLATE_LIST, partnertemplatelist);
}


export function* watchLoginUser():any {
    yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser():any {
    yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser():any {
    yield takeEvery(REGISTER_USER, register);
}

export function* watchGoogleUser():any {
    yield takeEvery(GOOGLE_USER, googleauth);
}

export function* watchFacebookUser():any {
    yield takeEvery(FACEBOOK_USER, facebookauth);
}

export function* watchForgetPassword():any {
    yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

export function* watchNewPassword():any {
    yield takeEvery(NEW_PASSWORD, newpassword);
}

export function* watchPartnerVerify():any {
    yield takeEvery(PARTNER_VERIFY, partnerVerify);
}

export function* watchPartnerPortfolioList():any {
    yield takeEvery(PARTNER_PORTFOLIO_LIST, partnerportfoliolist);
}

export function* watchUserInfo():any {
    yield takeEvery(USER_INFO, userinfo);
}

export function* watchUserInfoSave():any {
    yield takeEvery(USER_INFO_SAVE, userinfosave);
}

function* authSaga():any {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser),
        fork(watchRegisterUser),
        fork(watchGoogleUser),
        fork(watchFacebookUser),
        fork(watchForgetPassword),
        fork(watchNewPassword),
        fork(watchPartnerVerify),
        fork(watchPartnerTemplateList),
        fork(watchPartnerPortfolioList),
        fork(watchUserInfo),
        fork(watchUserInfoSave)
    ]);
}

export default authSaga;