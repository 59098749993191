// @flow
import {
    SALE_REPORT,
    SALE_REPORT_SUCCESS,
    SALE_REPORT_FAILED,
    ORDER_REPORT,
    ORDER_REPORT_SUCCESS,
    ORDER_REPORT_FAILED
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    salereport: null,
    orderreport: null
};

type StoreAction = { type: string, payload: {} | string };
type State = { salereport?: {} | null, loading?: boolean, +value?: boolean, orderreport?: {} | null};

const Store = (state:State = INIT_STATE, action: StoreAction) => {
    switch (action.type) {
        case SALE_REPORT:
            return { ...state, loading: true };
        case SALE_REPORT_SUCCESS:
            return { ...state, salereport: action.payload, loading: false, error: null };
        case SALE_REPORT_FAILED:
            return { ...state, error: action.payload, loading: false };
        case ORDER_REPORT:
            return { ...state, loading: true };
        case ORDER_REPORT_SUCCESS:
            return { ...state, orderreport: action.payload, loading: false, error: null };
        case ORDER_REPORT_FAILED:
            return { ...state, error: action.payload, loading: false };
        default: return { ...state };
    }
}

export default Store;