import {
    APPLICATION_INSTALL,
    APPLICATION_INSTALL_SUCCESS,
    APPLICATION_INSTALL_FAILED,
    APPLICATION_LIST,
    APPLICATION_LIST_SUCCESS,
    APPLICATION_LIST_FAILED,
    APPLICATION_UNINSTALL,
    APPLICATION_UNINSTALL_SUCCESS,
    APPLICATION_UNINSTALL_FAILED,
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    finishloading: false,
    applications: null
};

type ApplicationAction = { type: string, payload: {} | string };
type State = { applications?: {} | null, loading?: boolean, +value?: boolean, finishloading?: boolean };

const Application = (state: State = INIT_STATE, action: ApplicationAction) => {
    switch (action.type) {
        case APPLICATION_LIST:
            return {...state, loading: true};
        case APPLICATION_LIST_SUCCESS:
            return {...state, applications: action.payload, loading: false, error: null};
        case APPLICATION_LIST_FAILED:
            return {...state, error: action.payload, loading: false};
        case APPLICATION_INSTALL:
            return {...state, loading: true};
        case APPLICATION_INSTALL_SUCCESS:
            return {...state, applications: action.payload, loading: false, error: null};
        case APPLICATION_INSTALL_FAILED:
            return {...state, error: action.payload, loading: false};
        case APPLICATION_UNINSTALL:
            return {...state, loading: true};
        case APPLICATION_UNINSTALL_SUCCESS:
            return {...state, applications: action.payload, loading: false, error: null};
        case APPLICATION_UNINSTALL_FAILED:
            return {...state, error: action.payload, loading: false};
        default:
            return {...state};
    }
}

export default Application;
