import {
    APPLICATION_LIST,
    APPLICATION_LIST_SUCCESS,
    APPLICATION_LIST_FAILED,
    APPLICATION_INSTALL_FAILED,
    APPLICATION_INSTALL_SUCCESS,
    APPLICATION_INSTALL, APPLICATION_UNINSTALL, APPLICATION_UNINSTALL_FAILED, APPLICATION_UNINSTALL_SUCCESS,
} from '../../constants/actionTypes';

type ApplicationAction = { type: string, payload: {} | string };

export const applicationList = (token: string, siteId: string): ApplicationAction => ({
    type: APPLICATION_LIST,
    payload: {token, siteId}
});

export const applicationListSuccess = (applications: string): ApplicationAction => ({
    type: APPLICATION_LIST_SUCCESS,
    payload: applications
});

export const applicationListFailed = (error: string): ApplicationAction => ({
    type: APPLICATION_LIST_FAILED,
    payload: error
});

export const applicationInstall = (token: string, siteId: string, app_slug: string): ApplicationAction => ({
    type: APPLICATION_INSTALL,
    payload: {token, siteId, app_slug}
});

export const applicationInstallSuccess = (applications: string): ApplicationAction => ({
    type: APPLICATION_INSTALL_SUCCESS,
    payload: applications
});

export const applicationInstallFailed = (error: string): ApplicationAction => ({
    type: APPLICATION_INSTALL_FAILED,
    payload: error
});

export const applicationUnInstall = (token: string, siteId: string, app_slug: string): ApplicationAction => ({
    type: APPLICATION_UNINSTALL,
    payload: {token, siteId, app_slug}
});

export const applicationUnInstallSuccess = (applications: string): ApplicationAction => ({
    type: APPLICATION_UNINSTALL_SUCCESS,
    payload: applications
});

export const applicationUnInstallFailed = (error: string): ApplicationAction => ({
    type: APPLICATION_UNINSTALL_FAILED,
    payload: error
});
