// @flow
import {
    SITE_LIST,
    SITE_LIST_SUCCESS,
    SITE_LIST_FAILED,
    SITE_SCREENSHOT,
    SITE_SCREENSHOT_SUCCESS,
    SITE_SCREENSHOT_FAILED,
    SITE_CLEAR,
    SITE_BUILDER,
    SITE_BUILDER_SUCCESS,
    SITE_BUILDER_FAILED,
    SITE_ACCESS_TOKEN,
    SITE_ACCESS_TOKEN_SUCCESS,
    SITE_ACCESS_TOKEN_FAILED,
    SITE_TEMPLATE_LIST,
    SITE_TEMPLATE_LIST_SUCCESS,
    SITE_TEMPLATE_LIST_FAILED,
    SITE_NOASSIGN_LIST,
    SITE_NOASSIGN_LIST_SUCCESS,
    SITE_NOASSIGN_LIST_FAILED,
} from '../../constants/actionTypes';

type SiteAction = { type: string, payload: {} | string };

export const siteList = (token: string): SiteAction => ({
    type: SITE_LIST,
    payload: {token}
});

export const siteListSuccess = (sites: string): SiteAction => ({
    type: SITE_LIST_SUCCESS,
    payload: sites
});

export const siteListFailed = (error: string): SiteAction => ({
    type: SITE_LIST_FAILED,
    payload: error
});
export const siteScreenShot = (siteid: string, token: string): SiteAction => ({
    type: SITE_SCREENSHOT,
    payload: {siteid,token}
});

export const siteScreenShotSuccess = (site: string): SiteAction => ({
    type: SITE_SCREENSHOT_SUCCESS,
    payload: site
});

export const siteScreenShotFailed = (error: string): SiteAction => ({
    type: SITE_SCREENSHOT_FAILED,
    payload: error
});

export const siteClear = (): SiteAction => ({
    type: SITE_CLEAR
});

export const siteBuilder = (site_name: string,template_id: string, site_plan: string, parent_site_id: string, partner_id: string, token: string): SiteAction => ({
    type: SITE_BUILDER,
    payload: {site_name,template_id,site_plan,parent_site_id,partner_id,token}
});

export const siteBuilderSuccess = (sites: string): SiteAction => ({
    type: SITE_BUILDER_SUCCESS,
    payload: sites
});

export const siteBuilderFailed = (error: string): SiteAction => ({
    type: SITE_BUILDER_FAILED,
    payload: error
});

export const siteAccessToken = (siteid: string, username: string, method: string, token: string): SiteAction => ({
    type: SITE_ACCESS_TOKEN,
    payload: {siteid,username,method,token}
});

export const siteAccessTokenSuccess = (accesstoken: string): SiteAction => ({
    type: SITE_ACCESS_TOKEN_SUCCESS,
    payload: accesstoken
});

export const siteAccessTokenFailed = (error: string): SiteAction => ({
    type: SITE_ACCESS_TOKEN_FAILED,
    payload: error
});

export const siteTemplateList = (token: string): SiteAction => ({
    type: SITE_TEMPLATE_LIST,
    payload: {token}
});

export const siteTemplateListSuccess = (templates: string): SiteAction => ({
    type: SITE_TEMPLATE_LIST_SUCCESS,
    payload: templates
});

export const siteTemplateListFailed = (error: string): SiteAction => ({
    type: SITE_TEMPLATE_LIST_FAILED,
    payload: error
});

export const siteNoassignList = (token: string): SiteAction => ({
    type: SITE_NOASSIGN_LIST,
    payload: {token}
});

export const siteNoassignListSuccess = (siteonassigns: string): SiteAction => ({
    type: SITE_NOASSIGN_LIST_SUCCESS,
    payload: siteonassigns
});

export const siteNoassignListFailed = (error: string): SiteAction => ({
    type: SITE_NOASSIGN_LIST_FAILED,
    payload: error
});