// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { myConfig } from '../config';

import {
    SITE_LIST,
    SITE_SCREENSHOT,
    SITE_BUILDER,
    SITE_ACCESS_TOKEN,
    SITE_TEMPLATE_LIST,
    SITE_NOASSIGN_LIST
} from '../../constants/actionTypes';


import {
    siteListSuccess,
    siteListFailed,
    siteScreenShotSuccess,
    siteScreenShotFailed,
    siteBuilderSuccess,
    siteBuilderFailed,
    siteAccessTokenSuccess,
    siteAccessTokenFailed,
    siteTemplateListSuccess,
    siteTemplateListFailed,
    siteNoassignListSuccess,
    siteNoassignListFailed
} from './actions';


/**
 * Fetch data from given url
 * @param {*} url 
 * @param {*} options 
 */
const fetchJSON = (url, options = {}) => {
    return fetch(url, options)
        .then(response => {
            if (!response.status === 200) {
                throw response.json();
            }
            return response.json();
        })
        .then(json => {
            return json;
        })
        .catch(error => { throw error });
}


function* sitelist({ payload: { token } }) {
    const options = {
        body: JSON.stringify(),
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/projects`, options);
        if(response['status']){
            yield put(siteListSuccess(response['data']));
        }
        else{
            yield put(siteListFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(siteListFailed(message));
    }
}

function* sitescreenshot({ payload: { siteid,token } }) {
    const options = {
        body: JSON.stringify(),
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/site/${siteid}/screenshot`, options);
        if(response['status']){
            yield put(siteScreenShotSuccess(response['data']));
        }
        else{
            yield put(siteScreenShotFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(siteScreenShotFailed(message));
    }
}

function* sitebuilder({ payload: { site_name,template_id,site_plan,parent_site_id,partner_id,token } }) {
    const options = {
        body: JSON.stringify({ site_name,template_id,site_plan,parent_site_id,partner_id }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/site/builderbytemplate`, options);
        if(response['status']){
            window.open(`${response['data']['site']['site_url']}/wp-login.php?u=${response['data']['site']['user_email']}&token=${response['data']['token']}&method=elementor&itsec-hb-token=kndjyqtzmr`, '_blank');
            yield put(siteBuilderSuccess(response['data']));
        }
        else{
            yield put(siteBuilderFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(siteBuilderFailed(message));
    }
}

function* siteaccesstoken({ payload: { siteid,username,method,token } }) {
    const options = {
        body: JSON.stringify({ siteid }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/wp/access_token`, options)
        if(response['status']){
            //console.log(`${response['data']['site']['site_url']}/wp-login.php?u=${username}&token=${response['data']['token']}&method=${(method !== 'dashboard') ? (response['data']['site']['site_builder'] !== '2' ? response['data']['site']['site_template'] : 'salepage') : method}&itsec-hb-token=kndjyqtzmr`);

            window.open(`${response['data']['site']['site_url']}/wp-login.php?u=${username}&token=${response['data']['token']}&method=${(method !== 'dashboard') ? 'elementor' : method}&itsec-hb-token=kndjyqtzmr`, '_blank');
            yield put(siteAccessTokenSuccess(response['data']['token']));
        }
        else{
            yield put(siteAccessTokenFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(siteAccessTokenFailed(message));
    }
}

function* sitetemplatelist({ payload: { token } }) {
    const options = {
        body: JSON.stringify(),
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/site/templates`, options);
        if(response['status']){
            yield put(siteTemplateListSuccess(response['data']));
        }
        else{
            yield put(siteTemplateListFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(siteTemplateListFailed(message));
    }
}

function* sitenoassignlist({ payload: { token } }) {
    const options = {
        body: JSON.stringify(),
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/site/sitenoassignlist`, options);
        if(response['status']){
            yield put(siteNoassignListSuccess(response['data']));
        }
        else{
            yield put(siteNoassignListFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(siteTemplateListFailed(message));
    }
}

export function* watchSiteList():any {
    yield takeEvery(SITE_LIST, sitelist);
}
export function* watchSiteScreenShot():any {
    yield takeEvery(SITE_SCREENSHOT, sitescreenshot);
}
export function* watchSiteBuilder():any {
    yield takeEvery(SITE_BUILDER, sitebuilder);
}
export function* watchSiteAccessToken():any {
    yield takeEvery(SITE_ACCESS_TOKEN, siteaccesstoken);
}
export function* watchSiteTemplateList():any {
    yield takeEvery(SITE_TEMPLATE_LIST, sitetemplatelist);
}
export function* watchSiteNoassignList():any {
    yield takeEvery(SITE_NOASSIGN_LIST, sitenoassignlist);
}

function* siteSaga():any {
    yield all([
        fork(watchSiteList),
        fork(watchSiteScreenShot),
        fork(watchSiteBuilder),
        fork(watchSiteAccessToken),
        fork(watchSiteTemplateList),
        fork(watchSiteNoassignList),
    ]);
}

export default siteSaga;