export const myConfig = { 
    lgApiUrl:  'https://platform.wpbuilder.site/api' //'http://localhost:8888/platform-wpbuilder/api'
    ,lgApiKey: '392pVpaVkvrWU9V6ZVjkryGxRZ9zK2Ne'
    ,lgStoreApiUrl: 'https://store.ligor.cloud/api'
    ,omiseApiUrl: 'https://api.omise.co'
    ,omisePublicKey : 'pkey_test_5jf03fpl15v86xpc8lx'
    ,omiseSecretKey: 'skey_test_5jf03fpl2dntxtz5o99'
    ,omiseSecretKeyBtoa: 'c2tleV90ZXN0XzVqZjAzZnBsMmRudHh0ejVvOTk=' //https://www.w3schools.com/jsref/tryit.asp?filename=tryjsref_win_btoa
    ,stripePublicKey : 'pk_test_51LDQCwGUwABcL5h9RgNg5HmLwIBD0S7vyV0ikEcXn0drdO3dTCarJCKnnVMJiyBheUdIORdjr1FOy2I11izznT3D00sE2Nq3Vn'
    ,stripeSecretKey: 'sk_test_51LDQCwGUwABcL5h9eGwQJGN4q3IwGuNXBkDCYC56rVDZBwIkzOUcPpZ6fpXMbJqMUVi3zXbWXkC4P1yhgwpdf5wn00SMMNS6QC'
    ,productionIP: '165.22.101.106'
    ,productionServerID: '360269'
    ,targetCname: 'proxy-app.ligor.cloud'
    ,partnerToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJJRCI6IjEiLCJ1c2VyX2xvZ2luIjoiV1BBZG1pbiIsInVzZXJfcGFzcyI6ImZYdDlrRXF4OTloS0tzZXlodzlHK003NW9ONmdLRXhIXC9pTno4bXFkeVlQREE2NEFBZ1JMOGhtWmh5XC8zWTR0dlZySzloSG5JWThEQXMrcDFadnBzRHc9PSIsInVzZXJfcGFzc19oYXNoIjoiJDJ5JDEwJDM4QmE3aWthLjNWdkZsZERvRTFlOXV1WmV6dnZmMm9pMVd4VkhSUWVYU3NpT0RNTzVZQWZtIiwid3BfcGFzcyI6IlRBTGFoajI2dkpsemVwWHZVdjB2R3pDcFlRWlwvMzNuejdONEZscUxVV1NcL3FjNGpodTNLUVpaXC9xZG9xK2Q1XC91NEV5bjc0ZzV2YklmcVJ2YTJXY01CQT09Iiwid3BfcGFzc19oYXNoIjoiJDJ5JDEwJGV6QU1lVG9Zd0F5Q21YREk0Wk5Vck9kam1wSllOTUZpVkZNWlBURVhpYVl4cVwvbGY5Z0EwYSIsInVzZXJfZW1haWwiOiJzdXBwb3J0QGxpZ29yLm1lIiwidXNlcl90ZWwiOiIwODUxMzM0OTcxIiwiZGlzcGxheV9uYW1lIjoiV1BBZG1pbiIsInVzZXJfdHlwZSI6IjEiLCJ1c2VyX3JlZ2lzdGVyZWQiOiIyMDE4LTA1LTA4IDE1OjA0OjE5IiwidXNlcl9zdGF0dXMiOiIxIn0.0GFmshjl9X5kBpfL4Lpbu6Y-7oEy3uPNMZ6Qn6utU4U'
};