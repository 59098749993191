// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { myConfig } from '../config';

import {
    DASHBOARD_DISK_USAGE,
    DASHBOARD_SUMMARY,
    DASHBOARD_USERONLINE,
    DASHBOARD_HITS,
    DASHBOARD_BROWSER,
    DASHBOARD_PLATFORM,
    DASHBOARD_SITE_INFO
} from '../../constants/actionTypes';


import {
    dashboardDiskUsageSuccess,
    dashboardDiskUsageFailed,
    dashboardSummarySuccess,
    dashboardSummaryFailed,
    dashboardUserOnlineSuccess,
    dashboardUserOnlineFailed,
    dashboardHitsSuccess,
    dashboardHitsFailed,
    dashboardBrowserSuccess,
    dashboardBrowserFailed,
    dashboardPlatformSuccess,
    dashboardPlatformFailed,
    dashboardSiteInfoSuccess,
    dashboardSiteInfoFailed
} from './actions';


/**
 * Fetch data from given url
 * @param {*} url 
 * @param {*} options 
 */
const fetchJSON = (url, options = {}) => {
    return fetch(url, options)
        .then(response => {
            if (!response.status === 200) {
                throw response.json();
            }
            return response.json();
        })
        .then(json => {
            return json;
        })
        .catch(error => { throw error });
}


function* dashboarddiskusage({ payload: { site_id, token } }) {
    const options = {
        body: JSON.stringify({ site_id }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/wp/diskusage`, options);
        if(response['status']){
            yield put(dashboardDiskUsageSuccess(response['data']));
        }
        else{
            yield put(dashboardDiskUsageFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(dashboardDiskUsageFailed(message));
    }
}

function* dashboardsummary({ payload: { site_id, token } }) {
    const options = {
        body: JSON.stringify({ site_id }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/wp/summary`, options);
        if(response['status']){
            yield put(dashboardSummarySuccess(response['data']));
        }
        else{
            yield put(dashboardSummaryFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(dashboardSummaryFailed(message));
    }
}

function* dashboarduseronline({ payload: { site_id, token } }) {
    const options = {
        body: JSON.stringify({ site_id }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/wp/useronline`, options);
        if(response['status']){
            yield put(dashboardUserOnlineSuccess(response['data']));
        }
        else{
            yield put(dashboardUserOnlineFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(dashboardUserOnlineFailed(message));
    }
}

function* dashboardhits({ payload: { site_id, token } }) {
    const options = {
        body: JSON.stringify({ site_id }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/wp/hits`, options);
        if(response['status']){
            yield put(dashboardHitsSuccess(response['data']));
        }
        else{
            yield put(dashboardHitsFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(dashboardHitsFailed(message));
    }
}

function* dashboardbrowser({ payload: { site_id, token } }) {
    const options = {
        body: JSON.stringify({ site_id }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/wp/browser`, options);
        if(response['status']){
            yield put(dashboardBrowserSuccess(response['data']));
        }
        else{
            yield put(dashboardBrowserFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(dashboardBrowserFailed(message));
    }
}

function* dashboardplatform({ payload: { site_id, token } }) {
    const options = {
        body: JSON.stringify({ site_id }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/wp/platform`, options);
        if(response['status']){
            yield put(dashboardPlatformSuccess(response['data']));
        }
        else{
            yield put(dashboardPlatformFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(dashboardPlatformFailed(message));
    }
}

function* dashboardsiteinfo({ payload: { site_id, token } }) {
    const options = {
        body: JSON.stringify(),
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/site/${site_id}/info`, options);

        if(response['status']){
            yield put(dashboardSiteInfoSuccess(response['data']));
        }
        else{
            yield put(dashboardSiteInfoFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(dashboardPlatformFailed(message));
    }
}

export function* watchDashboardDiskUsage():any {
    yield takeEvery(DASHBOARD_DISK_USAGE, dashboarddiskusage);
}

export function* watchDashboardSummary():any {
    yield takeEvery(DASHBOARD_SUMMARY, dashboardsummary);
}

export function* watchDashboardUserOnline():any {
    yield takeEvery(DASHBOARD_USERONLINE, dashboarduseronline);
}

export function* watchDashboardHits():any {
    yield takeEvery(DASHBOARD_HITS, dashboardhits);
}

export function* watchDashboardBrowser():any {
    yield takeEvery(DASHBOARD_BROWSER, dashboardbrowser);
}

export function* watchDashboardPlatform():any {
    yield takeEvery(DASHBOARD_PLATFORM, dashboardplatform);
}

export function* watchDashboardSiteInfo():any {
    yield takeEvery(DASHBOARD_SITE_INFO, dashboardsiteinfo);
}

function* dashboardSaga():any {
    yield all([
        fork(watchDashboardSiteInfo),
        fork(watchDashboardDiskUsage),
        fork(watchDashboardSummary),
        fork(watchDashboardUserOnline),
        fork(watchDashboardHits),
        fork(watchDashboardBrowser),
        fork(watchDashboardPlatform),
    ]);
}

export default dashboardSaga;