// @flow
import {
    SALE_REPORT,
    SALE_REPORT_SUCCESS,
    SALE_REPORT_FAILED,
    ORDER_REPORT,
    ORDER_REPORT_SUCCESS,
    ORDER_REPORT_FAILED
} from '../../constants/actionTypes';

type StoreAction = { type: string, payload: {} | string };

export const saleReport = (siteid: string, period: string, token: string): StoreAction => ({
    type: SALE_REPORT,
    payload: {siteid,period,token}
});

export const saleReportSuccess = (salereport: string): StoreAction => ({
    type: SALE_REPORT_SUCCESS,
    payload: salereport
});

export const saleReportFailed = (error: string): StoreAction => ({
    type: SALE_REPORT_FAILED,
    payload: error
});

export const orderReport = (siteid: string, period: string, token: string): StoreAction => ({
    type: ORDER_REPORT,
    payload: {siteid,period,token}
});

export const orderReportSuccess = (salereport: string): StoreAction => ({
    type: ORDER_REPORT_SUCCESS,
    payload: salereport
});

export const orderReportFailed = (error: string): StoreAction => ({
    type: ORDER_REPORT_FAILED,
    payload: error
});