import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {myConfig} from '../config';

import {
    APPLICATION_LIST,
    APPLICATION_INSTALL, APPLICATION_UNINSTALL
} from '../../constants/actionTypes';


import {
    applicationListSuccess,
    applicationListFailed,
    applicationInstallSuccess,
    applicationInstallFailed,
    applicationUnInstallFailed,
    applicationUnInstallSuccess
} from './actions';


/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */
const fetchJSON = (url, options = {}) => {
    return fetch(url, options)
        .then(response => {
            if (!response.status === 200) {
                throw response.json();
            }
            return response.json();
        })
        .then(json => {
            return json;
        })
        .catch(error => {
            throw error
        });
}


function* applicationList({payload: {token, siteId}}) {
    const options = {
        body: JSON.stringify(),
        method: 'GET',
        headers: {'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/site/${siteId}/apps`, options);
        if (response['status']) {
            yield put(applicationListSuccess(response['data']));
        } else {
            yield put(applicationListFailed(response['message']));
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(applicationListFailed(message));
    }
}

function* applicationInstall({payload: {token, siteId, app_slug,}}) {
    const options = {
        body: JSON.stringify({
            "data":
                {
                    "plugin_path": app_slug
                }
        }),
        method: 'PUT',
        headers: {'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/site/${siteId}/apps/activate`, options);
        if (response['status']) {
            yield put(applicationInstallSuccess(response['data']));
        } else {
            yield put(applicationInstallFailed(response['message']));

        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(applicationInstallFailed(message));
    }
}

function* applicationUnInstall({payload: {token, siteId, app_slug,}}) {
    const options = {
        body: JSON.stringify({
            "data":
                {
                    "plugin_path": app_slug
                }
        }),
        method: 'PUT',
        headers: {'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/site/${siteId}/apps/deactivate`, options);
        if (response['status']) {
            yield put(applicationUnInstallSuccess(response['data']));
        } else {
            yield put(applicationUnInstallFailed(response['message']));

        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(applicationUnInstallFailed(message));
    }
}


export function* watchApplicationList(): any {
    yield takeEvery(APPLICATION_LIST, applicationList);
}

export function* watchApplicationInstall(): any {
    yield takeEvery(APPLICATION_INSTALL, applicationInstall);
}

export function* watchApplicationUnInstall(): any {
    yield takeEvery(APPLICATION_UNINSTALL, applicationUnInstall);
}

function* applicationSaga(): any {
    yield all([
        fork(watchApplicationList),
        fork(watchApplicationInstall),
        fork(watchApplicationUnInstall),
    ]);
}

export default applicationSaga;
