// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { myConfig } from '../config';

import {
    SALE_REPORT,
    ORDER_REPORT
} from '../../constants/actionTypes';


import {
    saleReportSuccess,
    saleReportFailed,
    orderReportSuccess,
    orderReportFailed
} from './actions';


/**
 * Fetch data from given url
 * @param {*} url 
 * @param {*} options 
 */
const fetchJSON = (url, options = {}) => {
    return fetch(url, options)
        .then(response => {
            if (!response.status === 200) {
                throw response.json();
            }
            return response.json();
        })
        .then(json => {
            return json;
        })
        .catch(error => { throw error });
}


function* salereport({ payload: { siteid,period,token } }) {
    const options = {
        body: JSON.stringify(),
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgStoreApiUrl}/store/${siteid}/reports/sales/${period}`, options);
        if(response['status']){
            yield put(saleReportSuccess(response['data']));
        }
        else{
            yield put(saleReportFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(saleReportFailed(message));
    }
}

function* orderreport({ payload: { siteid,period,token } }) {
    const options = {
        body: JSON.stringify(),
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgStoreApiUrl}/wp/${siteid}/reports/orders/${period}`, options);
        if(response['status']){
            yield put(orderReportSuccess(response['data']));
        }
        else{
            yield put(orderReportFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(orderReportFailed(message));
    }
}

export function* watchSaleReport():any {
    yield takeEvery(SALE_REPORT, salereport);
}
export function* watchOrderReport():any {
    yield takeEvery(ORDER_REPORT, orderreport);
}

function* storeSaga():any {
    yield all([
        fork(watchSaleReport),
        fork(watchOrderReport)
    ]);
}

export default storeSaga;