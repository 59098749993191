// @flow
import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILED,
    LOGOUT_USER,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAILED,
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_FAILED,
    NEW_PASSWORD,
    NEW_PASSWORD_SUCCESS,
    NEW_PASSWORD_FAILED,
    PARTNER_VERIFY,
    PARTNER_VERIFY_SUCCESS,
    PARTNER_VERIFY_FAILED,
    PARTNER_TEMPLATE_LIST,
    PARTNER_TEMPLATE_LIST_SUCCESS,
    PARTNER_TEMPLATE_LIST_FAILED,
    PARTNER_PORTFOLIO_LIST,
    PARTNER_PORTFOLIO_LIST_SUCCESS,
    PARTNER_PORTFOLIO_LIST_FAILED,
    USER_INFO,
    USER_INFO_SUCCESS,
    USER_INFO_FAILED,
    USER_INFO_SAVE,
    GOOGLE_USER,
    GOOGLE_USER_SUCCESS,
    GOOGLE_USER_FAILED,
    FACEBOOK_USER,
    FACEBOOK_USER_SUCCESS,
    FACEBOOK_USER_FAILED
} from '../../constants/actionTypes';

type AuthAction = { type: string, payload: {} | string };

export const loginUser = (username: string, password: string, partner_id: string, partner_brand: string, partner_system_name: string, partner_domain: string, partner_backend_url: string, partner_help_url: string, partner_favicon: string, partner_theme: string, partner_allow_crete_project: int, partner_logo: string): AuthAction => ({
    type: LOGIN_USER,
    payload: { username, password, partner_id, partner_brand, partner_system_name, partner_domain, partner_backend_url, partner_help_url, partner_favicon, partner_theme, partner_allow_crete_project, partner_logo }
});

export const loginUserSuccess = (user: string): AuthAction => ({
    type: LOGIN_USER_SUCCESS,
    payload: user
});

export const loginUserFailed = (error: string): AuthAction => ({
    type: LOGIN_USER_FAILED,
    payload: error
});

export const registerUser = (fullname: string, email: string, password: string, tel: string, partner_id: string, partner_brand: string, partner_system_name: string, partner_domain: string, partner_backend_url: string, partner_help_url: string, partner_favicon: string, partner_theme: string, partner_allow_crete_project: int, partner_logo: string): AuthAction => ({
    type: REGISTER_USER,
    payload: { fullname, email, password, tel, partner_id, partner_brand, partner_system_name, partner_domain, partner_backend_url, partner_help_url, partner_favicon, partner_theme, partner_allow_crete_project, partner_logo }
});

export const registerUserSuccess = (user: {}): AuthAction => ({
    type: REGISTER_USER_SUCCESS,
    payload: user
});

export const registerUserFailed = (error: string): AuthAction => ({
    type: REGISTER_USER_FAILED,
    payload: error
});

export const googleUser = (fullname: string, email: string, googleId: string, profile_image: string, partner_id: string, partner_brand: string, partner_system_name: string, partner_domain: string, partner_backend_url: string, partner_help_url: string, partner_favicon: string, partner_theme: string, partner_allow_crete_project: int, partner_logo: string): AuthAction => ({
    type: GOOGLE_USER,
    payload: { fullname, email, googleId, profile_image, partner_id, partner_brand, partner_system_name, partner_domain, partner_backend_url, partner_help_url, partner_favicon, partner_theme, partner_allow_crete_project, partner_logo }
});

export const googleUserSuccess = (user: {}): AuthAction => ({
    type: GOOGLE_USER_SUCCESS,
    payload: user
});

export const googleUserFailed = (error: string): AuthAction => ({
    type: GOOGLE_USER_FAILED,
    payload: error
});

export const facebookUser = (fullname: string, email: string, facebookId: string, profile_image: string, partner_id: string, partner_brand: string, partner_system_name: string, partner_domain: string, partner_backend_url: string, partner_help_url: string, partner_favicon: string, partner_theme: string, partner_allow_crete_project: int, partner_logo: string): AuthAction => ({
    type: FACEBOOK_USER,
    payload: { fullname, email, facebookId, profile_image, partner_id, partner_brand, partner_system_name, partner_domain, partner_backend_url, partner_help_url, partner_favicon, partner_theme, partner_allow_crete_project, partner_logo }
});

export const facebookUserSuccess = (user: {}): AuthAction => ({
    type: FACEBOOK_USER_SUCCESS,
    payload: user
});

export const facebookUserFailed = (error: string): AuthAction => ({
    type: FACEBOOK_USER_FAILED,
    payload: error
});


export const logoutUser = (history: any): AuthAction => ({
    type: LOGOUT_USER,
    payload: { history }
});

export const forgetPassword = (username: string): AuthAction => ({
    type: FORGET_PASSWORD,
    payload: { username }
});

export const forgetPasswordSuccess = (passwordResetStatus: string): AuthAction => ({
    type: FORGET_PASSWORD_SUCCESS,
    payload: passwordResetStatus
});

export const forgetPasswordFailed = (error: string): AuthAction => ({
    type: FORGET_PASSWORD_FAILED,
    payload: error
});

export const newPassword = (userid: string, password: string, newpassword: string, partner_id: string, token: string): AuthAction => ({
    type: NEW_PASSWORD,
    payload: { userid, password, newpassword, partner_id, token }
});

export const newPasswordSuccess = (passwordResetStatus: string): AuthAction => ({
    type: NEW_PASSWORD_SUCCESS,
    payload: passwordResetStatus
});

export const newPasswordFailed = (error: string): AuthAction => ({
    type: NEW_PASSWORD_FAILED,
    payload: error
});

export const partnerVerify = (hostname: string): AuthAction => ({
    type: PARTNER_VERIFY,
    payload: { hostname }
});

export const partnerVerifySuccess = (partner: string): AuthAction => ({
    type: PARTNER_VERIFY_SUCCESS,
    payload: partner
});

export const partnerVerifyFailed = (error: string): AuthAction => ({
    type: PARTNER_VERIFY_FAILED,
    payload: error
});

export const partnerTemplateList = (partnerid: string): AuthAction => ({
    type: PARTNER_TEMPLATE_LIST,
    payload: {partnerid}
});

export const partnerTemplateListSuccess = (templates: string): AuthAction => ({
    type: PARTNER_TEMPLATE_LIST_SUCCESS,
    payload: templates
});

export const partnerTemplateListFailed = (error: string): AuthAction => ({
    type: PARTNER_TEMPLATE_LIST_FAILED,
    payload: error
});

export const partnerPortfolioList = (partnerid: string): AuthAction => ({
    type: PARTNER_PORTFOLIO_LIST,
    payload: {partnerid}
});

export const partnerPortfolioListSuccess = (portfolios: string): AuthAction => ({
    type: PARTNER_PORTFOLIO_LIST_SUCCESS,
    payload: portfolios
});

export const partnerPortfolioListFailed = (error: string): AuthAction => ({
    type: PARTNER_PORTFOLIO_LIST_FAILED,
    payload: error
});

export const userInfo = (token: string): AuthAction => ({
    type: USER_INFO,
    payload: {token}
});

export const userInfoSuccess = (userinfo: string): AuthAction => ({
    type: USER_INFO_SUCCESS,
    payload: userinfo
});

export const userInfoFailed = (error: string): AuthAction => ({
    type: USER_INFO_FAILED,
    payload: error
});

export const userInfoSave = (data: string, token: string): AuthAction => ({
    type: USER_INFO_SAVE,
    payload: {data,token}
});