// @flow
import {
    SITE_LIST,
    SITE_LIST_SUCCESS,
    SITE_LIST_FAILED,
    SITE_SCREENSHOT,
    SITE_SCREENSHOT_SUCCESS,
    SITE_SCREENSHOT_FAILED,
    SITE_CLEAR,
    SITE_BUILDER,
    SITE_BUILDER_SUCCESS,
    SITE_BUILDER_FAILED,
    SITE_ACCESS_TOKEN,
    SITE_ACCESS_TOKEN_SUCCESS,
    SITE_ACCESS_TOKEN_FAILED,
    SITE_TEMPLATE_LIST,
    SITE_TEMPLATE_LIST_SUCCESS,
    SITE_TEMPLATE_LIST_FAILED,
    SITE_NOASSIGN_LIST,
    SITE_NOASSIGN_LIST_SUCCESS,
    SITE_NOASSIGN_LIST_FAILED
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    finishloading: false,
    sites: null,
    templates: null,
    siteonassigns: null
};

type SiteAction = { type: string, payload: {} | string };
type State = { sites?: {} | null, loading?: boolean, +value?: boolean, finishloading?: boolean, site?: {} | null, templates?: {} | null, siteonassigns?: {} | null, accesstoken?: string};

const Site = (state:State = INIT_STATE, action: SiteAction) => {
    switch (action.type) {
        case SITE_LIST:
            return { ...state, loading: true };
        case SITE_LIST_SUCCESS:
            return { ...state, sites: action.payload, loading: false, error: null };
        case SITE_LIST_FAILED:
            return { ...state, error: action.payload, loading: false };
        case SITE_SCREENSHOT:
            return { ...state, finishloading: false };
        case SITE_SCREENSHOT_SUCCESS:
            return { ...state, site: action.payload, finishloading: true, error: null };
        case SITE_SCREENSHOT_FAILED:
            return { ...state, error: action.payload, finishloading: true };
        case SITE_CLEAR:
            return { ...state, sites: null};
        case SITE_BUILDER:
            return { ...state, loading: true};
        case SITE_BUILDER_SUCCESS:
            return { ...state, sites: action.payload, loading: false, error: null };
        case SITE_BUILDER_FAILED:
            return { ...state, error: action.payload, loading: false };
        case SITE_ACCESS_TOKEN:
            return { ...state, loading: true};
        case SITE_ACCESS_TOKEN_SUCCESS:
            return { ...state, accesstoken: action.payload, loading: false, error: null };
        case SITE_ACCESS_TOKEN_FAILED:
            return { ...state, error: action.payload, loading: false };
        case SITE_TEMPLATE_LIST:
            return { ...state, loading: true };
        case SITE_TEMPLATE_LIST_SUCCESS:
            return { ...state, templates: action.payload, loading: false, error: null };
        case SITE_TEMPLATE_LIST_FAILED:
            return { ...state, error: action.payload, loading: false };
        case SITE_NOASSIGN_LIST:
            return { ...state, loading: true };
        case SITE_NOASSIGN_LIST_SUCCESS:
            return { ...state, siteonassigns: action.payload, loading: false, error: null };
        case SITE_NOASSIGN_LIST_FAILED:
            return { ...state, error: action.payload, loading: false };
        default: return { ...state };
    }
}

export default Site;